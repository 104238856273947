import {
  DirectionalLight,
  Scene,
  Vector3,
  Mesh,
  Quaternion,
  Matrix,
  AssetsManager,
  ShadowGenerator,
  NodeMaterial,
  PBRMaterial,
  StandardMaterial,
  CascadedShadowGenerator,
  AbstractMesh
} from "babylonjs";

import * as BABYLON from 'babylonjs';
import { RandomNumberBlock } from "babylonjs/Materials/Node/Blocks/randomNumberBlock";
import { CubeMapToSphericalPolynomialTools } from "babylonjs/Misc/HighDynamicRange/cubemapToSphericalPolynomial";


export class AssetLoader {
  private assetManager: BABYLON.AssetsManager;
  private flowerMaterial: BABYLON.PBRMaterial;
  public scene: Scene;
  constructor(scene: Scene) {
    this.assetManager = new BABYLON.AssetsManager(scene);
    this.scene = scene;
    this.flowerMaterial = new BABYLON.PBRMaterial("newFlowerMaterial" , scene);
    this.flowerMaterial.albedoColor = new BABYLON.Color3(1,1,1);

  }


  ApplyScatterMesh(rootName: string, scatterMeshPath: string, instacePaths: string[]){
    BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "./assets/",
      scatterMeshPath,
      this.scene
    ).then((newMesh) => {
      const vertData = newMesh.meshes[1].getVerticesData(
        BABYLON.VertexBuffer.PositionKind
      );
      newMesh.meshes[1].isVisible = false;
      let vertVectors = [];

      for (let i = 0; i < vertData.length / 3; i++) {
        const pos = new Vector3(
          vertData[i * 3] *-1,
          vertData[i * 3 + 1],
          vertData[i * 3 + 2],
        );
        
        vertVectors.push(pos);
      }
      return vertVectors;
    })
  }



  LoadRocksAndStuff(shadowCascadeGenerator: CascadedShadowGenerator) {
    ///FLOWERS
    var mainRoot = new BABYLON.TransformNode("InstanceRoot");
    BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "./assets/",
      "ScatterMesh_Flowers.glb",
      this.scene
    ).then((newMesh) => {
        const vertData = newMesh.meshes[1].getVerticesData(
          BABYLON.VertexBuffer.PositionKind
        );
        newMesh.meshes[1].isVisible = false;
        let vertVectors = [];
        for (let i = 0; i < vertData.length / 3; i++) {
          const pos = new Vector3(
            vertData[i * 3] * -1,
            vertData[i * 3 + 1],
            vertData[i * 3 + 2],
          );
          
          vertVectors.push(pos);
        }
        return vertVectors;
      })
      .then((vertVectors) => {
        var root = new BABYLON.TransformNode("flowers");
        root.parent = mainRoot;

        var unique = vertVectors.filter(onlyUnique);
        for (let p of unique) {
          var r =Math.floor(Math.random() * (2 - 1 + 1)) + 1;
          
          var meshTask = this.assetManager.addMeshTask(
            "flowerTask",
            "",
            "./assets/",
            `Flower_0${r}.glb`
          );
          let flowerMesh;
          meshTask.onSuccess = function (task) {
            flowerMesh = task.loadedMeshes[1];
            flowerMesh.parent = root;
            flowerMesh.position = p;
            flowerMesh.isVisible = true;
            //flowerMesh.material = this.flowerMaterial;
            //Dispose root node
            task.loadedMeshes[0].dispose();
            //let thing = task.loadedMeshes[0].getChildMeshes()[0];
            flowerMesh.addRotation(0, Math.random() * (2 - 1 + 1) + 1, 0);

            //flowerMesh.receiveShadows = true;
            //shadowCascadeGenerator.getShadowMap().renderList.push(flowerMesh)
            //shadowCascadeGenerator.transparencyShadow = true
          };
        }
      })


      //END FLOWERS

      ///ROCKS

      BABYLON.SceneLoader.ImportMeshAsync(
        "",
        "./assets/",
        "ScatterMesh_Stones.glb",
        this.scene
      ).then((newMesh) => {
        const vertData = newMesh.meshes[1].getVerticesData(
          BABYLON.VertexBuffer.PositionKind
        );
        newMesh.meshes[1].isVisible = false;
        let vertVectors = [];

        for (let i = 0; i < vertData.length / 3; i++) {
          const pos = new Vector3(
            vertData[i * 3] *-1,
            vertData[i * 3 + 1],
            vertData[i * 3 + 2],
          );
          
          vertVectors.push(pos);
        }
        return vertVectors;
      })
      .then((vertVectors) => {
        //var root = new BABYLON.TransformNode("rocks");
        

        var meshTask = this.assetManager.addMeshTask(
          "rockTask",
          "",
          "./assets/",
          `Stone_0${1}.glb`
        );
        let rockMesh: AbstractMesh; 
        let newMeshOfAllTheRocks = new AbstractMesh("abstract_AllRocks");
        newMeshOfAllTheRocks.parent = mainRoot;
        meshTask.onSuccess = function (task) {
          task.loadedMeshes[0].position = BABYLON.Vector3.Zero();
          task.loadedMeshes[0].isVisible = true;
          //task.loadedMeshes[0].parent = root;
          rockMesh = task.loadedMeshes[0].getChildMeshes()[0];
          
          // shadowCascadeGenerator.getShadowMap().renderList.push(rockMesh)
          // shadowCascadeGenerator.transparencyShadow = true;
          let allRocks = [];

          let i = 0;
          for (let p of vertVectors) {
            let newRock = rockMesh.clone(`rock_boy_${i}`, mainRoot);
            newRock.position = p;
            newRock.scaling = GetMeARandomVecor3(1, 3);
            newRock.rotation = GetMeARandomVecor3(1, 4);
            // newRock.position.y += .1;
            // shadowCascadeGenerator.getShadowMap().renderList.push(newRock);
            // shadowCascadeGenerator.transparencyShadow = true
            i++;
            allRocks.push(newRock);
          }
          
          newMeshOfAllTheRocks = BABYLON.Mesh.MergeMeshes(allRocks, true, true);
          newMeshOfAllTheRocks.parent = mainRoot;

          //newMeshOfAllTheRocks.position.y += .4;
          //newMeshOfAllTheRocks.receiveShadows = true;
          //shadowCascadeGenerator.getShadowMap().renderList.push(newMeshOfAllTheRocks);
          //shadowCascadeGenerator.transparencyShadow = true
        };
       })

  // Small Plant

     BABYLON.SceneLoader.ImportMeshAsync(
      "",
      "./assets/",
      "ScatterMesh_SmallPlant.glb",
      this.scene
    ).then((newMesh) => {
      const vertData = newMesh.meshes[1].getVerticesData(
        BABYLON.VertexBuffer.PositionKind
      );
      newMesh.meshes[1].isVisible = false;
      let vertVectors = [];

      for (let i = 0; i < vertData.length / 3; i++) {
        const pos = new Vector3(
          vertData[i * 3] *-1,
          vertData[i * 3 + 1],
          vertData[i * 3 + 2],
        );
        
        vertVectors.push(pos);
      }
      return vertVectors;
    })
    .then((vertVectors) => {
      //var root = new BABYLON.TransformNode("smallPlants");
      var meshTask = this.assetManager.addMeshTask(
        "smallPlantTask",
        "",
        "./assets/",
        `SmallPlant_01.glb`
      );
      let smallPlantMesh: AbstractMesh; 
      let newMeshOfAllTheSmallPlants = new AbstractMesh("abstract_AllPlants");
      newMeshOfAllTheSmallPlants.parent = mainRoot;
      meshTask.onSuccess = function (task) {
        task.loadedMeshes[0].position = BABYLON.Vector3.Zero();
        task.loadedMeshes[0].isVisible = true;
        //task.loadedMeshes[0].parent = root;
        smallPlantMesh = task.loadedMeshes[0].getChildMeshes()[0];
        
        //smallPlantMesh.receiveShadows = true;
        // shadowCascadeGenerator.getShadowMap().renderList.push(smallPlantMesh)
        // shadowCascadeGenerator.transparencyShadow = true;
        let allSmallPlants = [];

        let i = 0;
        for (let p of vertVectors) {
          let newSmallPlant = smallPlantMesh.clone(`smallPlant_boy_${i}`, mainRoot);
          newSmallPlant.position = p;
          newSmallPlant.addRotation(0, Math.random() * (2 - 1 + 1) + 1, 0);
          // shadowCascadeGenerator.getShadowMap().renderList.push(newRock);
          // shadowCascadeGenerator.transparencyShadow = true
          i++;
          allSmallPlants.push(newSmallPlant);
        }
        newMeshOfAllTheSmallPlants = BABYLON.Mesh.MergeMeshes(allSmallPlants, true, true);
        newMeshOfAllTheSmallPlants.parent = mainRoot;
        //newMeshOfAllTheSmallPlants.receiveShadows = true;
        //shadowCascadeGenerator.getShadowMap().renderList.push(newMeshOfAllTheSmallPlants);
        //shadowCascadeGenerator.transparencyShadow = true
      };
    })
  .then(() => {
      this.assetManager.load().onFinish = (tasks) => {}
   })

  }
}



function GetMeARandomVecor3(min: number, max: number) : BABYLON.Vector3{
  let x = Math.random() * (max - min + 1) + min;
  let y = Math.random() * (max - min + 1) + min;
  let z = Math.random() * (max - min + 1) + min;
  return new BABYLON.Vector3(x, y, z);
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}