import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import {
  Engine,
  HemisphericLight,
  Scene,
  Vector3,
  Node,
} from "babylonjs";


import { AssetLoader } from "./AssetLoader";

var canvas: any = document.getElementById("renderCanvas");
var engine: Engine = new Engine(canvas, true);
var characterMeshes = [];
var characterRoot;
var groundMesh;
var shadowCascadeGenerator;
var camera;
var lensEffect;
var defaultPipeline;
var box;
var skyboxMaterial;


function createScene(): Scene {
  var scene: Scene = new Scene(engine);
  let assetLoader = new AssetLoader(scene);

  //Create box
  box = BABYLON.MeshBuilder.CreateBox("box", {}, scene);
  box.position = new BABYLON.Vector3(3, 1, 0);
  box.scaling = new Vector3(0.3,0.3,0.3);
  box.isVisible = false;
  camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 4, new BABYLON.Vector3(0,1,0), scene);

  camera.wheelDeltaPercentage = 0.1;
  camera.allowUpsideDown = false;
  camera.lowerRadiusLimit = 2;
  camera.upperRadiusLimit = 10;

  //camera.setTarget(new BABYLON.Vector3(0, .7, 0));
  camera.inertia = .8;
  camera.checkCollisions = true;
  //camera.setTarget(box.getAbsolutePosition());
  camera.attachControl(canvas);
  camera.radius = 4;
  //var cameraArray = [camera];
  //Post processing
  // lensEffect = new BABYLON.LensRenderingPipeline('lens', {
  //   edge_blur: 1.0,
  //   chromatic_abberation: 0.3,
  //   distortion: 0,
  //   dof_focus_distance: 10,
  //   dof_aperture: 0.7,
  //   grain_amount: 1.0,
  //   penagonBokeh: true,
  //   dof_grain: 1.0,
  //   dof_threshold: 1.0,
  //   dof_darken: 0.025
  // }, scene, 1.0, cameraArray);


  defaultPipeline = new BABYLON.DefaultRenderingPipeline(
    "DefaultRenderingPipeline",
    true, // is HDR?
    scene,
    scene.cameras
 );
 defaultPipeline.fxaaEnabled = true;

    //Skybox
  var skybox = BABYLON.MeshBuilder.CreateBox("skyBox", {size:1000.0}, scene);
  skyboxMaterial = new BABYLON.StandardMaterial("skyBox", scene);
  skyboxMaterial.backFaceCulling = false;
  skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
        "assets/skybox/nicke", scene);
  skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
  skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
  skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
  skybox.material = skyboxMaterial;	


  //Directional light
  var directionalLight = new BABYLON.DirectionalLight("Light-Directional", new BABYLON.Vector3(3, -3, 3), scene);
  directionalLight.position = new BABYLON.Vector3(0, 2, 0);
  directionalLight.intensity = 0.4;
  
  // Ambient ligthing
  var ambientLight: HemisphericLight = new HemisphericLight("Light-ambient", new Vector3(1, 1, 0), scene);
  ambientLight.intensity = 0.8;
  ambientLight.diffuse = BABYLON.Color3.FromHexString("#FBE8FF");
  ambientLight.groundColor = BABYLON.Color3.FromHexString("#A9ABEB");

  //Cascade Shadow generator settings
  // shadowCascadeGenerator = new BABYLON.CascadedShadowGenerator(1024, directionalLight);
  // shadowCascadeGenerator.lambda = 0.5;
  // shadowCascadeGenerator.splitFrustum();
  // shadowCascadeGenerator.autoCalcDepthBounds = true;
  // shadowCascadeGenerator.shadowMaxZ = 200;
  // shadowCascadeGenerator.bias = 0.02;
  // shadowCascadeGenerator.transparencyShadow = true
  
  loadEnvironmentMesh();
  assetLoader.LoadRocksAndStuff(shadowCascadeGenerator);
  loadCharacterMesh();
  
  scene.onBeforeRenderObservable.add(() => {
    updateAllWorld(box);
    if(characterRoot != null){
      updateAllWorldChildren(characterRoot);
    }
    updateAllWorld(camera);
    camera.setTarget(box.getAbsolutePosition());
  });

  return scene;
}

function createFresnelMaterial(
  materialName: string,
  scene: Scene,
  textureName: string = null,
  fres_power: number = 2,
  fres_bias: number = 0.4,
  diffuseColor: string = null
  ){
  const standardMaterial = new BABYLON.StandardMaterial(materialName, scene);
  if(textureName != null){
    const texture = new BABYLON.Texture("./assets/"+textureName, scene);
    texture.vScale = -1;
    standardMaterial.diffuseTexture = texture;
  }
  if(diffuseColor != null){
    standardMaterial.diffuseColor = BABYLON.Color3.FromHexString(diffuseColor);
  }
  else{
    //Default to white if null
    standardMaterial.diffuseColor = new BABYLON.Color3(1,1,1);
  }
  standardMaterial.transparencyMode = 0;
  standardMaterial.specularPower = 10;
  standardMaterial.specularColor = new BABYLON.Color3(0.1,0.1,0.1);
  standardMaterial.roughness = 0;
  standardMaterial.emissiveColor = BABYLON.Color3.White();

  standardMaterial.emissiveFresnelParameters = new BABYLON.FresnelParameters();
  standardMaterial.emissiveFresnelParameters.power = fres_power;
  standardMaterial.emissiveFresnelParameters.bias = fres_bias;
  // outside color
  standardMaterial.emissiveFresnelParameters.leftColor = BABYLON.Color3.White();
  //Inside color
  standardMaterial.emissiveFresnelParameters.rightColor = BABYLON.Color3.Black();

  return standardMaterial;
}

function loadEnvironmentMesh(){
  BABYLON.SceneLoader.ImportMesh("", "./assets/", "Environment_Ground_PolyReduce.glb", scene, function (newMeshes){
      const groundMaterial = createFresnelMaterial("groundFresnel", scene, null, 4, 0.75, "#070707");
      groundMaterial.specularColor = new BABYLON.Color3(0,0,0);
      // Edge fresnel color 
      groundMaterial.emissiveFresnelParameters.leftColor = BABYLON.Color3.FromHexString("#2F3B6B");
      // Facing fresnel color 
      groundMaterial.emissiveFresnelParameters.rightColor = BABYLON.Color3.FromHexString("#131533");
      groundMesh = newMeshes[0].getChildMeshes()[0];
      groundMesh.material = groundMaterial;
      groundMesh.receiveShadows = true;
      groundMesh.checkCollisions = true;
      // scene.animationGroups[0].speedRatio = 2;
      shadowCascadeGenerator.getShadowMap().renderList.push(groundMesh);
  });
  BABYLON.SceneLoader.ImportMesh("", "./assets/", "Environment_BackgroundVista.glb", scene, function (newMeshes){
    const vistasMaterial = createFresnelMaterial("groundvistaFresnel", scene, null, 3.3, 0.7, "#070707");
    vistasMaterial.specularColor = new BABYLON.Color3(0,0,0);
    vistasMaterial.emissiveFresnelParameters.leftColor = BABYLON.Color3.FromHexString("#2F3B6B");
    vistasMaterial.emissiveFresnelParameters.rightColor = BABYLON.Color3.FromHexString("#131533"); 
    var vistasMesh = newMeshes[0].getChildMeshes()[0];
    vistasMesh.material = vistasMaterial;
    shadowCascadeGenerator.getShadowMap().renderList.push(vistasMesh);
  });

  // Load PinkSpheres
  BABYLON.SceneLoader.ImportMesh("", "./assets/", "PinkDualSphere_01.glb", scene, function (newMeshes){
    //newMeshes[1].material = 
  });
}


function loadCharacterMesh(){
  BABYLON.SceneLoader.ImportMesh("", "./assets/", "Character_RunAnimation_LongPath2.glb", scene, function(newMeshes){
    //start callback function
  // Assign meshes
  for (let mesh of newMeshes[0].getChildMeshes()){
    characterMeshes.push(mesh);
    mesh.receiveShadows = true;
    mesh.alwaysSelectAsActiveMesh = true
    //console.log(mesh.name);
    //shadowCascadeGenerator.getShadowMap().renderList.push(mesh);
  }


  characterRoot = newMeshes[0].getDescendants()[0];
  //console.log(characterRoot);

  

  

  
  const visorMaterial = new BABYLON.StandardMaterial("visorMaterial", scene);
  visorMaterial.diffuseColor = BABYLON.Color3.FromHexString("#3C2E68");
  visorMaterial.specularPower = 150;
  visorMaterial.emissiveColor = new BABYLON.Color3(0.05, 0.05, 0.05);
  visorMaterial.alpha = 0.6;
  visorMaterial.alphaMode = 2;
  visorMaterial.reflectionTexture = skyboxMaterial.reflectionTexture

  const mouthEyesMaterial = new BABYLON.PBRMaterial("FaceMaterial", scene);
  mouthEyesMaterial.albedoColor = new BABYLON.Color3(0,0,0);
  mouthEyesMaterial.roughness = 1;
  mouthEyesMaterial.metallic = 0;

  const babyBlue = new BABYLON.PBRMaterial("BabyBlueMaterial", scene);
  babyBlue.albedoColor = BABYLON.Color3.FromHexString("#627DE7");
  babyBlue.roughness = 1;
  babyBlue.metallic = 0;

  const pinkGradientMaterial = createFresnelMaterial("PinkGradientMaterial", scene, "PinkBlueGradient.png", 3.3, 0.59);
  
  const bodyTextureMaterial = createFresnelMaterial("bodyFresnelTexture", scene, "Body_BakedAlbedo.png", 3.3, 0.59);
  
  const purpleFresnelMaterial = createFresnelMaterial("purpleFresnel", scene, null, 0.5, 0.3, "#3B1F66");

  const orangeFresnelMaterial = createFresnelMaterial("orangeFresnel", scene, null, 3.3, 0.59, "#EE9775");

  const whiteFresnelMaterial = createFresnelMaterial("WhiteFresnel", scene, null, 3.3, 0.59, "#FFFFFF");

  const HotPinkFresnelMaterial = createFresnelMaterial("HotPinkFresnel", scene, null, 3.3, 0.59, "#FD70D6");

  const skinMaterial = new BABYLON.PBRMaterial("skinMaterial",scene);
  skinMaterial.transparencyMode = 0;
  skinMaterial.metallic = 0;
  skinMaterial.roughness = 0.5;
  skinMaterial.albedoColor = BABYLON.Color3.FromHexString("#D7A8C6");
  
  // camera
 
  // Assigns the new materials, matching the imported material names
  for (let mesh of characterMeshes){
    const materialName:string = mesh.material.name;
    //console.log(mesh.material.name);

    if(materialName == "HotPinkGradient"){
      mesh.material = pinkGradientMaterial;
    }
    if(materialName == "DeepPurple"){
      mesh.material = purpleFresnelMaterial;
    }
    if(materialName == "YellowOrange"){
      mesh.material = orangeFresnelMaterial;
    }
    if(materialName == "Body"){
      mesh.material = bodyTextureMaterial;
    }
    if(materialName == "HelmetVisor"){
      mesh.material = visorMaterial;
      //console.log(mesh)
    }
    if(materialName == "White"){
      mesh.material = whiteFresnelMaterial;
    }
    if(materialName == "HotPink"){
      mesh.material = HotPinkFresnelMaterial;
    }
    if(materialName == "Skin"){
      mesh.material = skinMaterial;
    }
    if(materialName == "FaceFeatures"){
      mesh.material = mouthEyesMaterial;
    }
    if(materialName == "BabyBlue"){
      mesh.material = babyBlue;
    }

  }
 

  });
}

function calculateMeshDistance(){
  // NOTE Calculate the position from the camera to the head and set focus distance
  if(characterMeshes[0] != null){
    var tempBox = box.getAbsolutePosition();
    var tempPostion = scene.skeletons[0].bones[0].getAbsolutePosition();
    var tempPostion = new Vector3(tempPostion.x*-1,tempPostion.y+0.7, tempPostion.z);
    var tempVector = Vector3.Lerp(tempBox, tempPostion, 0.5);
    box.setAbsolutePosition(tempVector);
    
    //camera.setTarget(box);
    //box.position
    //var distance = Vector3.Distance(camera.globalPosition , scene.skeletons[0].bones[5].getAbsolutePosition())*1000;
    //console.log("Focus on:"+scene.skeletons[0].bones[1].name+ scene.skeletons[0].bones[1].getAbsolutePosition());
    //defaultPipeline.depthOfField.focusDistance = distance;
    //lensEffect.setFocusDistance(distance);

  }
  
}

function updateAllWorld(node: Node) {
  if (node.parent) {
    updateAllWorld(node.parent);
  }
  node.computeWorldMatrix(true);
}

  // call on highest parent
function updateAllWorldChildren(node: Node) {
    node.getChildren().forEach(element => {
      element.computeWorldMatrix(true);
      updateAllWorldChildren(element);
    });
  }



var scene: Scene = createScene();
//scene.debugLayer.show();
engine.runRenderLoop(() => {
  calculateMeshDistance();
  engine.resize();
  scene.render();
});
